@import '../../../styles';

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    a:hover {
        text-decoration: none; }

    .card {
        padding: 30px 20px;
        border-radius: 4px; }

    button,
    .card {
        width: 350px;
        margin-bottom: 15px; } }

.background {
    background-color: $app-background-color;
    background-image: url(./../../assets/app/welcome-background.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

.backgroundStudent {
    background-image: url(./../../assets/student/welcome-background.jpg); }

.withoutTransform {
    text-transform: none !important; }
