.left {
    text-align: left; }

.formPage {
    max-width: 1000px; }

.accountRequestDescription {
    min-height: 95%;
    padding: 5px 0;
    border-left: 1px solid rgba(0,0,0,0.12);

    h3 {
        padding: 0 15px; }

    ul {
        text-align: left;

        li {
            margin-bottom: 15px; } } }
