$general-background-color: #fafafa;
$general-success-color: #4caf50;
$general-failed-color: #f44336;
$general-warning-color: #ff9800;
$general-amber-color: #ffc107;
$general-centervention-green-color: #125F30;
$general-centervention-light-green-color: #41b549;
$general-blue-color: #3f51b5;
$general-white-color: #fff;
$general-white-smoke-color: #f5f5f5;
$general-light-failed-color: #ef9a9a;
$general-selected-green-background-color: $general-centervention-green-color;
$general-selected-green-background-text-color: #fff;
$general-selected-blue-background-color: $general-blue-color;
$general-selected-blue-background-text-color: #fff;
$general-selected-light-blue-background-color: #e8eaf6;
$general-selected-light-success-background-color: #e8f5e9;
$general-selected-light-failed-background-color: #ffebee;
$general-selected-light-warning-background-color: #fff3e0;
$general-secondary-color: #f50057;

$panel-background-color: $general-white-color;
$sidebar-background-color: #1b2430;
$sidebar-submenu-background-color: #232f3e;

$tabulator-header-background-color: #e0f2f1;
$tabulator-td-border-color: #eee;
$tabulator-icon-color: #1769aa;
$tabulator-icon-delete-color: #ab003c;
$tabulator-icon-report-color: #e65100;
$tabulator-icon-time-played-color: $general-warning-color;
$tabulator-icon-checkbox-unchecked-color: #333;
$tabulator-icon-checkbox-checked-color: $general-secondary-color;

$icon-dismiss-color: #aeaeae;
$input-placeholder-color: #ccc;

$app-background-color: #85A192;
$academy-header-color: #8064E8;
$academy-background-color: #99D456;
$zoou-header-color: $general-centervention-green-color;
$zoou-background-color: #77A342;
$ssgrin-header-color: #33756d;
$ssgrin-background-color: $ssgrin-header-color;
$hoh-header-color: #88cfe3;
$hoh-background-color: $hoh-header-color;
$sim-header-color: #5fb8ff;
$sim-background-color: #FEDE92;

$emotion-check-in-mad: $general-failed-color;
$emotion-check-in-sad: $app-background-color;
$emotion-check-in-worried: $general-warning-color;
$emotion-check-in-okay: $general-blue-color;
$emotion-check-in-happy: $general-centervention-light-green-color;

$groupDataEmerging: #ffcdd2;
$groupDataImproving: #fff59d;
$groupDataProficient: #c8e6c9;

a {
    text-decoration: none;
    color: $general-blue-color;

    &:hover {
        text-decoration: underline; } }

$sidebar-size: 220px;
