@import '../../../../../styles';

.customHeader {
    height: 130px;

    .olivia {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 200px;
        height: 175px;
        background-image: url('../../../../assets/app/olivia-login.png');
        background-position-y: -25px; } }

.bottomContainer {
    width: 100%;
    padding: 30px 10px 10px;
    font-size: 0.85rem;
    color: #555;

    .divider {
        margin: 0 -16px 0 -24px; }

    button {
        font-size: 0.85rem; }

    p {
        margin-bottom: 5px;

        button {
            vertical-align: baseline; }

        &.password {
            margin-top: 0; } }

    .requestAccount {
        padding: 10px 0 15px;
        text-align: center;

        button {
            text-transform: none; } } }
