.header {
    img {
        max-width: 150px; } }

.footer {
    justify-content: flex-start;
    padding-left: 225px;
    background-image: url(./../../../assets/app/taiwan/logo.png);
    background-size: 150px auto;
    background-repeat: no-repeat;
    background-position: 50px center; }
