@import '../../../styles';

.notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    & > div {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 50px;
        border-radius: 4px;
        font-size: 1rem;
        max-width: 500px;

        p {
            flex: 1;
            margin: 0;
            padding: 0;
            text-align: center; }

        svg {
            margin-right: 25px;
            margin-left: -15px; } }

    &.inline {
        width: 100%;

        & > div {
            padding: 15px 40px;
            max-width: none;
            font-size: 0.9rem; } }

    &.big {
        & > div {
            font-size: 1.2rem; } }

    .success {
        color: $general-centervention-green-color;
        background-color: $general-selected-light-success-background-color; }

    .warning {
        color: $tabulator-icon-report-color;
        background-color: $general-selected-light-warning-background-color; }

    .info {
        color: $general-blue-color;
        background-color: $general-selected-light-blue-background-color; }

    .error {
        color: $tabulator-icon-delete-color;
        background-color: $general-selected-light-failed-background-color; }

    .default {
        background-color: $general-background-color; } }

.link {
    margin: 25px 0;

    &:hover {
        text-decoration: none; } }

.listSelectable {
    width: 100%;
    height: 100%;
    overflow: auto;

    .title {
        display: flex;

        h4 {
            flex: 1;
            margin: 12px 0;
            padding: 0 15px; }

        & > section {
            display: flex;
            align-items: center;

            button {
                padding: 8px;
                max-width: 35px;
                max-height: 35px; }

            button + button {
                margin-right: 5px; } } }

    .search {
        padding: 5px 15px;
        font-size: 0.9rem;

        &:before {
            border-bottom: 0; } }

    .loading {
        margin: 10px 15px; }

    .listItem {
        padding: 0 15px;

        &.selected {
            background-color: $general-selected-light-failed-background-color; }

        .text {
            span {
                font-size: 0.9rem; } } } }

.listTransferButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    button {
        margin: 5px 0;

        svg {
            width: 15px;
            height: 15px;
            margin: 0 10px; } } }

.confetti {
    position: fixed;
    top: 15%;
    left: 50%;
    z-index: 99999; }
