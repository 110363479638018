@import '../../../styles';

.error403,
.error404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 2em;

    svg {
        margin-bottom: 15px;
        font-size: 100px; } }

.error403 {
    color: $general-failed-color; }

.error404 {
    color: $general-warning-color; }
