@import '../../../styles';

.scrollableFormBody {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;

    h2 {
        & > span {
            display: block;
            font-size: 0.7em;
            font-weight: normal;
            color: $general-secondary-color; } } }

.field {
    input,
    select {
        padding: 14px; }

    input,
    select,
    label {
        font-size: 14px;
        line-height: 16px; }

    span {
        font-size: 1em; }

    label {
        top: -6px;

        &[data-shrink=true] {
            transform: matrix(0.80, 0, 0, 0.80, 15, -1); } }

    legend {
        white-space: nowrap; } }

.fieldHidden {
    p {
        padding: 16px 24px; }

    *:before,
    *:after {
        border: 0 !important; } }

.studentUsernamePrefix {
    margin-left: -10px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #aaa;
    background: #eee;
    border-radius: 4px 0 0 4px; }

.switch {
    margin-top: -6px;
    margin-bottom: -6px;

    span {
        font-size: 15px; } }

.error {
    color: $general-failed-color !important; }

.inputHelperText {
    padding: 0 14px; }

.autocompleteInput {
    & > div {
        padding-top: 5.5px !important;
        padding-bottom: 5.5px !important; } }

.autocompleteOption {
    font-size: 0.9rem !important; }

.autocompleteReversedContainer {
    button {
        display: none; }

    li {
        opacity: 0.75 !important; } }

.autocompleteReversedOption {
    font-size: 0.8rem !important;
    padding-top: 5px;
    padding-bottom: 5px;

    span {
        font-size: 0.8rem !important;
        color: $general-warning-color; } }

.autocompleteReversedPopper {
    li {
        opacity: 1 !important;
        padding-top: 0;
        padding-bottom: 0;
        color: #ccc; } }

.autocompleteOptionSearchUsersBar {
    width: 100%;
    display: flex;

    svg {
        width: 18px !important;
        margin-right: 12px;
        margin-left: -8px; }

    .label {
        flex: 1; }

    &.admin {
        svg {
            color: $sim-header-color; }

        .status {
            div {
                min-width: 150px;
                font-size: 0.85em;
                text-align: center; } } }

    &.student {
        svg {
            color: $academy-header-color; }

        .active {
            color: $general-success-color;
            font-size: 0.9em; }

        .archived {
            color: $general-failed-color;
            font-size: 0.9em; } } }

.educatorAccounts {
    margin: 12px !important;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
    padding: 6px;
    padding-bottom: 0;
    background-color: $general-selected-light-blue-background-color;
    border-radius: 8px;

    & > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 1.2em;
        padding: 12px !important;
        padding-right: 0 !important; }

    & > div {
        padding-top: 0 !important;
        padding-bottom: 2px !important; }

    &.disconnected {
        background-color: $general-selected-light-failed-background-color;
        border-color: transparent; } }

.teamParticipants {
    display: flex;

    .educatorsList {
        height: 500px;
        flex: 10; }

    .transferButtons {
        margin: 0 25px;
        flex: 2; }

    .participantsList {
        height: 500px;
        flex: 10; }

    .adminsList {
        height: 175px;
        margin-bottom: 25px; }

    .membersList {
        height: 300px; } }

.file {
    padding: 25px;
    text-align: center;
    border: 1px dashed #aaa;
    border-radius: 4px;
    cursor: pointer;

    * {
        cursor: pointer; }

    label {
        color: #aaa;

        h4 {
            margin: 0; } }

    div {
        margin-top: 15px; }

    .icon {
        margin-right: 5px;
        font-size: 1.1em; } }

.assignProgram {
    h3 {
        margin: 0;
        margin-bottom: 10px; }

    h3 + .subLabel {
        color: $general-secondary-color;
        margin-top: -10px;
        margin-bottom: 10px; }

    & > label {
        margin-top: 2px; }

    .freeTrial {
        display: flex;

        & > span {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 110px;
            padding-right: 5px; }

        & + .freeTrial {
            margin-top: 10px; } }

    .skip {
        margin-top: 10px; }

    button {
        flex: 1;

        &.selected {
            color: #fff;
            background-color: $general-centervention-green-color; }

        &.switchDefaultProgram {
            padding: 1px 15px;

            span {
                font-size: 0.9em;
                font-weight: normal;
                text-transform: none;
                white-space: nowrap;
                padding: 0 15px; }

            &.selected {
                color: #fff;
                background-color: $general-selected-blue-background-color; } }

        &:disabled {
            img {
                opacity: 0.25; } } }

    .desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        padding: 5px 25px;
        white-space: nowrap;
        text-transform: none;
        font-weight: normal;

        p {
            margin: 0;
            line-height: 1.3em;
            font-weight: bold; }

        span {
            font-weight: normal;
            font-size: 0.95em; }

        img {
            max-height: 30px;
            max-width: 90px;
            margin-bottom: 5px; } }

    p {
        margin-left: 0; } }

.inputSwitchGroup {
    h3 {
        margin-top: 0.8em !important;
        text-align: left; }

    .switch {
        padding-bottom: 0.8em; } }

.bulkResult {
    padding: 15px;
    background-color: $general-background-color;

    .bulkSummary > div {
        justify-content: space-between; }

    .bulkDetails {
        padding: 0; } }

.formPage {
    text-align: center; }

.formPageHeader,
.formPageFooter {
    display: flex;
    padding: 25px 0; }

.formPageHeader {
    h5 {
        flex: 1;
        font-size: 1.6rem;
        text-align: center; } }

.formPageFooter {
    justify-content: center; }

.securePassword {
    svg {
        width: 30px !important; } }
