@import '../../../styles';

div {
    .header {
        .iconButton {
            position: absolute;
            top: 6px;
            right: 6px;
            color: $icon-dismiss-color;

            .icon {
                width: 1em;
                font-size: 0.7em; } }

        .hideButton {
            right: 45px; } }

    .footer {
        justify-content: center;
        flex-wrap: wrap; } }
