@import '../../../styles';

.icon {
    margin-right: 15px;
    font-size: 18px; }

.dismissIcon {
    font-size: 16px;
    min-width: 16px;
    opacity: 0.5;
    color: $general-white-color; }

.notifications {
    button {
        margin: 0 10px 0 40px;
        color: $general-white-color; } }
