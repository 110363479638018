@import '../../../styles';

.container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.main {
    flex: 1;
    padding: 30px;
    min-width: 100%; }

.header,
.footer {
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;
    min-width: 100%; }

.card {
    background-color: $panel-background-color;
    border-radius: 8px; }

.shadow {
    -moz-box-shadow: 0 0 10px 0 rgba(50, 65, 80, .5);
    -webkit-box-shadow: 0 0 10px 0 rgba(50, 65, 80, .5);
    box-shadow: 0 0 10px 0 rgba(50, 65, 80, .5); }
